import {gql} from "@apollo/client";
import {BannerFragment} from "./Banner";
import {StorageFragment} from "./Storage";
import {DesignBlockSettingFragment} from "./DesignBlockSetting";
import {DesignBlockCardFragment} from "./DesignBlockCard";
import {DesignBlockTextFragment} from "./DesignBlockText";
import {DesignBlockBannerFragment} from "./DesignBlockBanner";
import {BannerFragmentAdmin} from "./Banner";
import {DesignBlockSettingFragmentAdmin} from "./DesignBlockSetting";
import {DesignBlockCardFragmentAdmin} from "./DesignBlockCard";
import {DesignBlockTextFragmentAdmin} from "./DesignBlockText";

export const DesignBlockFragment = gql`
	fragment DesignBlockFragment on DesignBlock {
		id
		type
		position
		active
		title_in_color
		title_position
		full_width
		margin_top
		margin_bottom
		min_height
		background
		title
		subtitle
		var_1
		var_2
		var_3
		var_4
		var_5
		block_start
		block_end
	}
`

export const DesignBlockFragmentAdmin = gql`
	fragment DesignBlockFragmentAdmin on DesignBlock {
		id
		type
		position
		active
		title_in_color
		title_position
		full_width
		margin_top
		margin_bottom
		min_height
		background
		title
		titles
		subtitle
		subtitles
		var_1
		var_2
		var_3
		var_4
		var_5
		block_start
		block_end
	}
`

export const GetDesignBlocks = gql`
	${DesignBlockFragment}
	${BannerFragment}
	${StorageFragment}
	${DesignBlockSettingFragment}
	${DesignBlockCardFragment}
	${DesignBlockTextFragment}
	query ($code: String!, $active: Boolean, ) {
		GetDesignBlocks (code: $code, active: $active, ) {
			...DesignBlockFragment
			banners			{
				...BannerFragment
				image				{
					...StorageFragment
				}
				mobile_image				{
					...StorageFragment
				}
			}
			settings			{
				...DesignBlockSettingFragment
				image				{
					...StorageFragment
				}
			}
			cards			{
				...DesignBlockCardFragment
				image				{
					...StorageFragment
				}
			}
			text			{
				...DesignBlockTextFragment
			}
		}
	}
`

export const GetDesignBlocksAdmin = gql`
	${DesignBlockFragmentAdmin}
	${DesignBlockBannerFragment}
	${BannerFragmentAdmin}
	${StorageFragment}
	${DesignBlockSettingFragmentAdmin}
	${DesignBlockCardFragmentAdmin}
	${DesignBlockTextFragmentAdmin}
	query ($code: String!, ) {
		GetDesignBlocksAdmin (code: $code, ) {
			...DesignBlockFragmentAdmin
			design_blocks_banners			{
				...DesignBlockBannerFragment
				banner				{
					...BannerFragmentAdmin
					images					{
						...StorageFragment
					}
					mobile_images					{
						...StorageFragment
					}
				}
			}
			settings			{
				...DesignBlockSettingFragmentAdmin
				image				{
					...StorageFragment
				}
			}
			cards			{
				...DesignBlockCardFragmentAdmin
				image				{
					...StorageFragment
				}
			}
			text			{
				...DesignBlockTextFragmentAdmin
			}
		}
	}
`

export const CreateDesignBlock = gql`
	${DesignBlockFragmentAdmin}
	mutation ($code: String, $type: String, $position: Int, ) {
		CreateDesignBlock (code: $code, type: $type, position: $position, ) {
			...DesignBlockFragmentAdmin
		}
	}
`

export const UpdateDesignBlock = gql`
	${DesignBlockFragmentAdmin}
	mutation ($id: ID!, $title: String, $title_position: String, $subtitle: String, $active: Boolean, $block_start: String, $block_end: String, $title_in_color: Boolean, $full_width: Boolean, $margin_bottom: String, $margin_top: String, $min_height: Int, $background: String, $var_1: String, $var_2: String, $var_3: String, $var_4: String, $var_5: String, $attach_banner: ID, ) {
		UpdateDesignBlock (id: $id, title: $title, title_position: $title_position, subtitle: $subtitle, active: $active, block_start: $block_start, block_end: $block_end, title_in_color: $title_in_color, full_width: $full_width, margin_bottom: $margin_bottom, margin_top: $margin_top, min_height: $min_height, background: $background, var_1: $var_1, var_2: $var_2, var_3: $var_3, var_4: $var_4, var_5: $var_5, attach_banner: $attach_banner, ) {
			...DesignBlockFragmentAdmin
		}
	}
`

export const DeleteDesignBlock = gql`
	${DesignBlockFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteDesignBlock (id: $id, ) {
			...DesignBlockFragmentAdmin
		}
	}
`

