import {gql} from "@apollo/client";

export const JobFormResponseFragment = gql`
	fragment JobFormResponseFragment on JobForm {
		error
		success
	}
`

export const JobContactForm = gql`
	${JobFormResponseFragment}
	mutation ($ucenje: Boolean, $posao: Boolean, $ime: String!, $prezime: String!, $ulica: String!, $zip: String!, $mesto: String!, $email: String!, $telefon: String!, $files: [Upload], ) {
		JobContactForm (ucenje: $ucenje, posao: $posao, ime: $ime, prezime: $prezime, ulica: $ulica, zip: $zip, mesto: $mesto, email: $email, telefon: $telefon, files: $files, ) {
			...JobFormResponseFragment
		}
	}
`

