
const APP_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
            ? "http://192.168.40.131:3000"
            : window.location.origin;

function server(path) {
    if (path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://192.168.40.131:5003/${path}`;
    }
    return `${window.location.origin}/${path}`;
}

const config =  {
    modules: {
        _app: {
            color: '#7ab935',
            server: server,
            appUrl: APP_URL,
            graphQL: server("graphql"),
        },
        lang: {
            languages: [
                {id: 0, short: 'sr', value: "/", name: "Srpski", label: "RS"},
                {id: 1, short: 'en', value: "/home", name: "English", label: "EN"}
            ]
        },
        alerts: {},
        apollo: {},
        cms: {
            tokenName: "admntokjwt"
        },
        settings: {
            image: true
        },
        pages: {
            menus: [
                {id: 1, name: "Public"},
                {id: 2, name: "FAQ"},
                {id: 3, name: "Layouts"},
                {id: 4, name: "Protected"},
                {id: 100, name: "CMS"},
                {id: 101, name: "SEO"},
            ]
        },
        storage: {
            root: "storage",
            folder: "kvmrsk",
            maxUpload: 8000000,
            server: (path) => {
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    return `http://kavim-raska.rs/${path}`;
                }
                return server(path)
            },
        },
        public: {},
        /*categories: {
            image: true,
            maxDepth: 2,
        },*/
        banners: {
            /*placement: {
                "categoriesmenu": {
                    name: "usluge strana",
                    description: "usluge strana desc",
                },
                "symptomright": {
                    name: "simptom strana",
                    description: "simptom strana desc"
                },
            }*/
        },
        design: {
            settings: {
                image: true
            },
            cards: {
                image: true
            },
            blocks: {
                banners: {
                    type: "banners",
                    name: "baneri",
                    cmsElement: "banners.CmsDesignBlock",
                    element: "banners.DesignBlock",
                },
                editor: {
                    type: "editor",
                    name: "text editor",
                    cmsElement: "texteditor.CmsDesignBlock",
                    element: "texteditor.DesignBlock",
                },
                /*categories: {
                    type: "categories",
                    name: "kategorije u carouselu",
                    cmsElement: "categories.CmsDesignBlock",
                    element: "categories.DesignBlock",
                },*/
                blogs: {
                    type: "blogs",
                    name: "poslednje vesti",
                    cmsElement: "blogs.CmsDesignBlock",
                    element: "blogs.DesignBlock",
                },
                cards: {
                    type: "cards",
                    name: "naslov, text i slika",
                    cmsElement: "design.CmsDesignBlockCards",
                    element: "design.DesignBlockCards",
                },
                imagetext: {
                    type: "imagetext",
                    name: "text i slika (jedno pored drugog)",
                    cmsElement: "design.CmsDesignBlockImageText",
                    element: "design.DesignBlockImageText",
                },
                jobform: {
                    type: "jobform",
                    name: "Forma za posao",
                    cmsElement: "forms.CmsDesignBlockJobForm",
                    element: "forms.DesignBlockJobForm",
                },
            },
        },
        nestable: {},
        seo: {},
        //products: {},
        texteditor: {},
        blogs: {},
        forms: {}
    },
}
export default config;